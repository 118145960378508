<template>
  <div
    v-if="loading || articles.length > 0"
    class="news d-flex align-items-center my-1"
  >
    <div class="ticker-label">
      News
    </div>
    <div class="news-text w-100 px-1">
      <transition
        name="fade"
        mode="out-in"
      >
        <a
          :key="currentNews"
          :href="articles[currentNews].Url"
          target="_blank"
        >{{ articles[currentNews].Title }}</a>
      </transition>
    </div>
    <div class="news-control ml-auto">
      <span>
        {{ currentNews + 1 }} / {{ articles.length }}
      </span>
      <b-button
        variant="flat"
        class="btn-icon"
        @click="previous"
      >
        <feather-icon icon="ChevronLeftIcon" />
      </b-button>
      <b-button
        variant="flat"
        class="btn-icon"
        @click="next"
      >
        <feather-icon icon="ChevronRightIcon" />
      </b-button>
    </div>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      currentNews: 0,
      articles: [],
      loading: false,
      timer: null,
    }
  },
  mounted() {
    this.loadArticles()
    this.timer = setInterval(() => {
      this.next()
    }, 5000)
  },
  methods: {
    next() {
      this.currentNews += 1
      if (this.currentNews >= this.articles.length) {
        this.currentNews = 0
      }
      this.resetInterval()
    },
    previous() {
      this.currentNews -= 1
      if (this.currentNews < 0) {
        this.currentNews = this.articles.length - 1
      }
      this.resetInterval()
    },
    loadArticles() {
      this.loading = true
      this.$http.get('news.cfc?method=getArticles')
        .then(response => {
          this.articles = response.data.articles
        })
        .catch(() => {
          console.log('Error loading articles')
        })
        .then(() => {
          this.loading = false
        })
    },
    resetInterval() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.next()
      }, 5000)
    },
  },
}
</script>
<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import '~@core/scss/base/bootstrap-extended/variables';
@import 'bootstrap/scss/variables';

.news {
  border-radius: 6px;
  background-color: #fdfdfe;
  min-width: 0;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.dark-layout .news {
  background-color: #2c2e45 !important;
}

.ticker-label {
  color: #636363;
  font-weight: 500;
  padding: 8px 8px 8px 21px;
  border-radius: 3px 18px 18px 3px;
}

.dark-layout .ticker-label {
  color: #d0d2d6
}

.news-text {
  // max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-layout .news-text a {
  color: $success;
}

.news-control {
  min-width: 110px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
