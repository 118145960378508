<template>
  <b-card
    v-if="series"
    :title="title"
  >
    <b-card-sub-title
      v-if="explanation"
      class="mb-1"
    >
      {{ explanation }}
    </b-card-sub-title>
    <loading-container v-if="loading" />
    <no-data-container v-else-if="!series || series.length < 1" />
    <b-card-text
      v-else
    >
      <vue-apex-charts
        type="line"
        height="300"
        :options="chartOptions"
        :series="series"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import { $themeColors, $chartColors } from '@themeConfig'
import { BCard, BCardSubTitle, BCardText } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import dayjs from 'dayjs'
import LoadingContainer from './LoadingContainer.vue'
import NoDataContainer from './NoDataContainer.vue'

export default {
  components: {
    BCard,
    BCardSubTitle,
    BCardText,
    VueApexCharts,
    LoadingContainer,
    NoDataContainer,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    explanation: {
      type: String,
      default: '',
    },
    series: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: {
            show: true,
            offsetY: -25,
            tools: {
              download: false,
              selection: false,
              zoomin: false,
              zoomout: false,
            },
          },
          zoom: {
            type: 'xy',
            enabled: true,
          },
        },
        colors: [$themeColors.warning, $themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.info,
          $chartColors[0], $chartColors[1], $chartColors[2], $chartColors[3], $chartColors[4]],
        stroke: {
          // width: [3, 3],
          width: 3,
          curve: 'smooth',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
        },
        tooltip: {
          x: {
            formatter(val) {
              return dayjs(val).format('YYYY-MM-DD')
            },
          },
          shared: false,
          marker: false,
        },
        xaxis: {
          type: 'datetime',
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: true,
            formatter(val) {
              return dayjs(val).format('YYYY-MM-DD')
            },
          },
          labels: {
            show: true,
            showDuplicates: false,
            formatter(val) {
              return dayjs(val).format('YYYY-MM-DD')
            },
          },
        },
        yaxis: [{
          title: {
            text: 'Value',
          },
          decimalsInFloat: 2,
        }],
      },
    }
  },
}
</script>
