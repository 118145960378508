<template>
  <div>
    <template v-if="!serviceError">
      <news-ticker v-if="appBrand==='srp'" />

      <ecommerce-statistics
        :title="`Product Universe for ${data.servicename}`"
        :updated="`Last updated as at ${data.updated}`"
        explanation="Summary of the current state of the product universe. All entries except the last one refer to live products"
        :data="data.productUniverse"
        :loading="serviceLoading"
        :show-updated="true"
      />

      <b-row>
        <b-col>
          <b-card
            no-body
            class="weighting-mode-card px-2 py-75"
          >
            <div class="d-flex align-items-center">
              <span class="mr-50">Product weighting</span>
              <product-weighting-help class="mr-50" />
              <v-select
                v-model="productWeightMode.selectedMode"
                :options="productWeightModeOptions"
                :reduce="mode => mode.value"
                :clearable="false"
                label="name"
                style="min-width: 220px"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col lg="6">
          <index-chart
            title="Structured Product Index"
            explanation="Investor Benchmark, Underlyings basket, and selected calculated Structured Product indices"
            :series="data.index"
            :loading="chartsLoading"
          />
        </b-col>
        <b-col lg="6">
          <ecommerce-order-chart
            explanation="Breakdown of possible and final future maturities"
            :loading="serviceLoading"
            :height="300"
            :data="data.futureMaturities"
          />
        </b-col>
      </b-row>
    </template>
    <error-display
      v-else
      @action-clicked="getservice"
    />

    <template v-if="!chartsError">
      <b-row class="match-height">
        <b-col lg="6">
          <race-chart
            title="Product Trends"
            explanation="Animation showing products striking over rolling last 12 months"
            :data="data.race"
            :loading="chartsLoading"
          />
        </b-col>

        <b-col lg="6">
          <report-table-card
            title="Market Data"
            explanation="Performance of Structured Product Index, Market Benchmark and most popular underlyings"
            table-header
            :fields="data.marketData.header"
            :items="data.marketData.content"
          />
        </b-col>

      </b-row>

    </template>
    <error-display
      v-else
      @action-clicked="getcharts"
    />

    <template v-if="!userError">
      <ecommerce-statistics
        title="My Portfolios"
        explanation="A summary of my portfolios and links to view them. This table is also available on the portfolios page"
        :data="data.portfolioSummary"
        :loading="userLoading"
      />

      <b-card no-body>
        <portfolios-table
          :table-data="portfolios"
          :loading="portfoliosLoading"
          @portfolioDeleted="onPortfolioDeleted"
          @row-clicked="portfolioClicked"
        />
        <no-data-container
          v-if="!portfoliosLoading && (!portfolios || portfolios.length < 1)"
          message="You do not have any portfolios yet"
          action="Create Portfolio"
          @action-clicked="onCreatePortfolioClicked"
        />
      </b-card>
    </template>
    <error-display
      v-else
      @action-clicked="getuser"
    />

    <b-modal
      v-model="showCreatePortfolioModal"
      hide-footer
      centered
      title="Create Empty Portfolio"
    >
      <create-portfolio-modal-content
        :portfolios="portfolios"
        description="empty"
        @portfolio-created="onPortfolioCreated"
      />
    </b-modal>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BModal,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import vSelect from 'vue-select'
import CreatePortfolioModalContent from '@/views/components/CreatePortfolioModalContent.vue'
import NewsTicker from '@/views/components/NewsTicker.vue'
import EcommerceStatistics from './ecommerce/EcommerceStatistics.vue'
import EcommerceOrderChart from './ecommerce/EcommerceOrderChart.vue'
import IndexChart from './components/IndexChart.vue'
import RaceChart from './components/RaceChart.vue'
import PortfoliosTable from './components/PortfoliosTable.vue'
import ReportTableCard from './components/ReportTableCard.vue'
import NoDataContainer from './components/NoDataContainer.vue'
import ErrorDisplay from './components/ErrorDisplay.vue'
import ProductWeightingHelp from './components/ProductWeightingHelp.vue'

export default {
  components: {
    CreatePortfolioModalContent,
    NoDataContainer,
    BRow,
    BCol,
    BCard,
    BModal,
    EcommerceStatistics,
    EcommerceOrderChart,
    IndexChart,
    ReportTableCard,
    RaceChart,
    PortfoliosTable,
    ErrorDisplay,
    NewsTicker,
    vSelect,
    ProductWeightingHelp,
  },
  data() {
    return {
      /* eslint-disable global-require */
      data: {
        servicename: '',
        updated: '',
        productUniverse: [
          {
            icon: '',
            color: '',
            title: '',
            subtitle: '',
            customClass: '',
          },
        ],
        futureMaturities: {
          categories: [],
          series: [
            {
              name: 'Maturities',
              data: [],
            },
          ],
        },
        returns: [{
          data: [
          ],
        }],
        index: [],
        race: {},
        marketData: {
          header: [],
          content: [],
        },
        holdingsAndReturns: {
          underlyings: {
            holdings: [],
            returns: [],
          },
          issuers: {
            holdings: [],
            returns: [],
          },
          productTypes: {
            holdings: [],
            returns: [],
          },
        },
        portfolioSummary: [],
      },
      serviceLoading: false,
      chartsLoading: false,
      userLoading: false,
      serviceError: null,
      chartsError: null,
      userError: null,
      showCreatePortfolioModal: false,

      // Product Weight Mode
      productWeightMode: {
        allowedMode: [],
        allowedModeName: [],
        selectedMode: 'default',
      },
    }
  },
  computed: {
    appBrand() {
      return $themeConfig.app.brand
    },
    portfolios() {
      return this.$store.state.app.portfolios
    },
    portfoliosLoading() {
      return this.$store.state.app.portfolioLoading
    },
    productWeightModeOptions() { // Base options based on allowedMode and allowedModeName
      const selectOptions = []
      this.productWeightMode.allowedMode.forEach((mode, index) => {
        selectOptions.push({ name: this.productWeightMode.allowedModeName[index], value: mode })
      })
      return selectOptions
    },
  },
  watch: {
    'productWeightMode.selectedMode': function (val, oldVal) {
      // Check if the old was not 'default'
      if (oldVal === 'default') {
        return
      }

      // Reload charts
      this.getcharts()
    },
  },
  created() {
    this.changeall()
    this.$store.dispatch('app/updatePortfolios')
  },
  activated() {
    this.getuser()
  },
  methods: {
    changeall() {
      this.getservice()
      this.getcharts()
      // this.getuser()
    },
    getservice() {
      this.serviceLoading = true

      // Reset Error flag
      this.serviceError = null

      this.$http
        .get('data.cfc?method=getHomePage')
        .then(response => {
          this.data.servicename = response.data.info.servicename
          this.data.updated = response.data.info.updated
          this.data.productUniverse = response.data.data.productuniverse.items
        })
        .catch(error => {
          console.log('Service Loading Error: ', error)
          this.serviceError = true
        })
        .then(() => {
          this.serviceLoading = false
        })
    },

    getcharts() {
      this.chartsLoading = true

      // Reset Error flag
      this.chartsError = null

      this.$http
        .get('data.cfc?method=getHomePageCharts', {
          params: {
            productweightingmode: this.productWeightMode.selectedMode,
          },
        })
        .then(response => {
          this.data.index = response.data.INDEXCHART.SHOWDATA.DATA
          this.data.futureMaturities = response.data.FUTUREMATURITIES

          this.data.returns = response.data.RETURNS
          this.data.marketData = response.data.MARKETDATA
          this.data.race = response.data.RACEDATA

          // Product Weight Mode
          this.productWeightMode.allowedMode = response.data.allowedmode
          this.productWeightMode.allowedModeName = response.data.allowedmodename
          this.productWeightMode.selectedMode = response.data.selectedmode
        })
        .catch(error => {
          console.log('Charts Loading Error: ', error)
          this.chartsError = true
        })
        .then(() => {
          this.chartsLoading = false
        })
    },

    getuser() {
      this.userLoading = true

      // Reset error flag
      this.userError = null

      this.$http
        .get('portfolio.cfc?method=getUserPortfolioInfoHome').then(response => {
          this.data.portfolioSummary = response.data.userproductuniverse.items
        })
        .catch(error => {
          console.log('User Loading Error: ', error)
          this.userError = true
        })
        .then(() => {
          this.userLoading = false
        })
    },

    portfolioClicked(item, openInNewTab = false) {
      this.clickedPortfolioId = item.portfolioId
      if (openInNewTab) {
        const route = this.$router.resolve({ name: 'portfolio-report', params: { portfolioId: this.clickedPortfolioId } })
        window.open(route.href, '_blank')
      } else {
        this.$router.push({ name: 'portfolio-report', params: { portfolioId: this.clickedPortfolioId } })
      }
      return item
    },
    onCreatePortfolioClicked() {
      this.showCreatePortfolioModal = true
      console.log('Create Portfolio Clicked.')
    },
    onPortfolioCreated() {
      // Hide create portfolio modal
      this.showCreatePortfolioModal = false

      // Reload Portfolios List
      this.$store.dispatch('app/updatePortfolios')
    },
    onPortfolioDeleted() {
      // Reload user data
      this.changeall()
      this.getuser()

      // Update portfolios list inside the store
      this.$store.dispatch('app/updatePortfolios')
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
  .weighting-mode-card {
    margin-top: -1rem;
  }
</style>
