<template>
  <b-card
    v-if="data"
  >
    <b-card-title>
      {{ title }}
      <b-form-group class="float-right">
        <b-form-select
          v-model="selectedRace"
          :options="raceOptions"
        />
      </b-form-group>
    </b-card-title>
    <b-card-sub-title
      v-if="explanation"
      class="mb-1"
    >
      {{ explanation }}
    </b-card-sub-title>
    <loading-container v-if="loading" />
    <no-data-container v-else-if="!data[selectedRace] || data[selectedRace].length < 1" />
    <b-card-text
      v-else
    >
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-row">
          <b-button
            variant="gradient-primary"
            class="btn-icon rounded-circle"
            @click="previous"
          >
            <feather-icon icon="ChevronsLeftIcon" />
          </b-button>
          <b-button
            variant="gradient-danger"
            class="btn-icon rounded-circle mx-1"
            @click="racingIntervalId ? pause() : play()"
          >
            <feather-icon :icon="racingIntervalId ? 'PauseIcon' : 'PlayIcon'" />
          </b-button>
          <b-button
            variant="gradient-primary"
            class="btn-icon rounded-circle"
            @click="next"
          >
            <feather-icon icon="ChevronsRightIcon" />
          </b-button>
        </div>
        <div class="w-100 px-1 align-self-center">
          <vue-slider
            v-model="selectedYearIndex"
            :min="0"
            :max="data[selectedRace].length - 1"
            :interval="1"
            dir="ltr"
            tooltip="none"
          />
        </div>
        <span class="align-self-center text-right race-chart-label">{{ label }}</span>
      </div>
      <vue-apex-charts
        v-if="productColors.length > 0"
        ref="racingChart"
        class="lighten-datalabel"
        type="bar"
        height="340"
        :options="selectedChartOptions"
        :series="data[selectedRace][0]"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCardText, BFormGroup, BFormSelect, BButton,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import VueApexCharts from 'vue-apexcharts'
import LoadingContainer from './LoadingContainer.vue'
import NoDataContainer from './NoDataContainer.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BFormGroup,
    BFormSelect,
    VueApexCharts,
    LoadingContainer,
    NoDataContainer,
    VueSlider,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    explanation: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedRace: 'underlying',
      raceOptions: [
        { text: 'Underlyings', value: 'underlying' },
        { text: 'Issuers', value: 'issuer' },
        { text: 'Product Types', value: 'producttype' },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          animations: {
            easing: 'linear',
            speed: 500,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          formatter: val => (val === 0.0000000000001 ? '' : val),
        },
        colors: [
          ({ dataPointIndex }) => this.getBarColor(dataPointIndex),
        ],
        xaxis: {
          title: {
            text: 'Proportion',
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 320,
            maxWidth: 320,
            style: {
              fontSize: '13px',
            },
            formatter: val => (val === '.' ? '' : val),
          },
        },
        grid: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: -20,
          },
        },
      },
      selectedYearIndex: 0,
      racingIntervalId: null,
      label: '',
      chartColors: [
        '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231',
        '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe',
        '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000',
        '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080',
        '#ffffff', '#000000',
      ],
      productColors: [],
      maxNumberOfEntries: 0,
      roundMaxValue: 5,
      maxValue: 100,
      maxValueAtLastIndex: 100,
    }
  },
  computed: {
    selectedChartOptions() {
      const options = { ...this.chartOptions }

      if (this.selectedRace === 'underlying') {
        options.yaxis.labels.maxWidth = 240
        options.yaxis.labels.minWidth = 240
      } if (this.selectedRace === 'issuer') {
        options.yaxis.labels.maxWidth = 240
        options.yaxis.labels.minWidth = 240
      } if (this.selectedRace === 'producttype') {
        options.yaxis.labels.maxWidth = 180
        options.yaxis.labels.minWidth = 180
      }

      // Calculate sensible maximum for x-axis
      if (this.maxValue > this.maxValueAtLastIndex * 1.2) {
        // Big disparity between overall maximum and maximum at last index, use half-way between two
        options.xaxis.max = (this.maxValue + this.maxValueAtLastIndex) / 2
      } else {
        // Overall maximum and maximum at last index are close enough, use the overall maximum
        options.xaxis.max = this.maxValue
      }
      options.xaxis.tickAmount = options.xaxis.max / this.roundMaxValue

      return options
    },
  },
  watch: {
    data() {
      setTimeout(() => {
        this.selectedYearIndex = this.data[this.selectedRace].length - 1
      }, 500)
      this.calculateOverallMaxValue()
      this.calculateMaxNumberOfEntries()
      this.createProductColor()
    },
    selectedRace() {
      this.calculateOverallMaxValue()
      this.calculateMaxNumberOfEntries()
      this.createProductColor()
      this.selectedYearIndex += 1
      setTimeout(() => {
        this.selectedYearIndex -= 1
      }, 500)
    },
    selectedYearIndex() {
      const sortedSeries = this.sortSeries(this.data[this.selectedRace][this.selectedYearIndex])
      this.$refs.racingChart.updateSeries(sortedSeries, true)
      this.label = this.data[this.selectedRace][this.selectedYearIndex][0].label
    },
  },
  mounted() {
    this.calculateOverallMaxValue()
    this.calculateMaxNumberOfEntries()
    this.createProductColor()
  },
  methods: {
    play() {
      this.racingIntervalId = setInterval(() => {
        this.selectedYearIndex += 1
        if (this.selectedYearIndex === this.data[this.selectedRace].length - 1) {
          this.pause()
        } else if (this.selectedYearIndex > this.data[this.selectedRace].length - 1) {
          this.selectedYearIndex = 0
        }
      }, 500)
    },
    pause() {
      clearInterval(this.racingIntervalId)
      this.racingIntervalId = null
    },
    next() {
      if (this.selectedYearIndex + 1 >= this.data[this.selectedRace].length) {
        this.selectedYearIndex = 0
      } else {
        this.selectedYearIndex += 1
      }
    },
    previous() {
      if (this.selectedYearIndex < 1) {
        this.selectedYearIndex = this.data[this.selectedRace].length - 1
      } else {
        this.selectedYearIndex -= 1
      }
    },
    sortSeries(series) {
      let sortedSeries = series[0].data
      sortedSeries = sortedSeries.sort((a, b) => b.y - a.y)
      const difference = this.maxNumberOfEntries - sortedSeries.length
      for (let i = 0; i < difference; i += 1) {
        sortedSeries.push({ x: '.', y: 0.0000000000001 })
      }
      return [{ data: sortedSeries }]
    },
    createProductColor() {
      // Loop through all the year data
      let colorIndex = 0

      if (!this.data[this.selectedRace]) {
        return
      }

      this.productColors = []
      const race = JSON.parse(JSON.stringify(this.data[this.selectedRace])) // Make a deep copy
      race.reverse()
      race.forEach(instance => {
        instance[0].data.forEach(data => {
          if (this.productColors.findIndex(pc => pc.name === data.x) === -1) {
            this.productColors.push({ name: data.x, color: colorIndex })
            colorIndex += 1
            if (colorIndex >= this.chartColors.length) colorIndex = 0
          }
        })
      })
      this.productColors.push({ name: '.', color: 0 })
    },
    getBarColor(dataPointIndex) {
      const productName = this.data[this.selectedRace][this.selectedYearIndex][0].data[dataPointIndex].x
      const color = this.productColors.find(pc => pc.name === productName)
      return this.chartColors[color.color]
    },
    calculateMaxNumberOfEntries() {
      const data = this.data[this.selectedRace]
      let max = 0

      data.forEach(d => {
        const dLength = d[0].data.length
        max = dLength > max ? dLength : max
      })

      this.maxNumberOfEntries = max
    },
    calculateMaxValue(data) {
      let maxValue = 0
      data.forEach(item => {
        const roundedY = Math.ceil(item.y / this.roundMaxValue) * this.roundMaxValue
        maxValue = Math.max(maxValue, roundedY)
      })
      return maxValue
    },
    calculateOverallMaxValue() {
      if (!this.data[this.selectedRace]) {
        return
      }

      const numIndices = this.data[this.selectedRace].length

      let maxValue = 0
      let maxValueAtLastIndex = 0
      for (let i = 0; i < numIndices; i += 1) {
        const mv = this.calculateMaxValue(this.data[this.selectedRace][i][0].data)
        if (i === numIndices - 1) {
          maxValueAtLastIndex = mv
        }
        maxValue = Math.max(maxValue, mv)
      }

      this.maxValue = maxValue
      this.maxValueAtLastIndex = maxValueAtLastIndex
    },
  },
}
</script>

<style scoped>
.race-chart-label {
  min-width: 90px;
  font-size: 1.4rem;
  font-weight: bold;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
